
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
























































































































































@import "~@/scss/dashboardTable";

::v-deep {
  table {
    thead {
      tr {
        th {
          border-radius: 0 !important;

          .th-wrap.is-relative {
            position: static !important;
          }
          .icon.sort-icon {
            right: 0 !important;
            left: auto !important;
          }
        }
      }
    }
  }
}
