
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        









































































































































































































































































































































































.level {
    .level-left, .level-right {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.dashboard-actions__radio-cover {
    margin-left: 10px;
}

.dashboardBody {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
            "banner"
            "chart"
            "offers";
}

.chart {
    grid-area: chart;
}

.banner {
    grid-area: banner;
}

.offers {
    grid-area: offers;
}

//noinspection SassScssUnresolvedMixin
@include fullhd {
    .dashboardBody {
        grid-template-columns: 66.75% 33.25%;
        grid-template-areas:
            "chart offers";
    }
}
