.card {
  width: 100%;
  height: 100%;

  color: #15161A;
  font-size: 0.875rem;
  box-shadow: 0 0 10px rgba(172, 172, 172, 0.25);

  ::v-deep {
    .card-content {
      height: 100%;
      padding: 1.5rem;
    }
  }
}

.count-card {
  font-size: 2.3rem;
  line-height: 1;
  overflow-wrap: break-word;
}