
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


















































































.skeleton-centered::v-deep {
    .b-skeleton-item {
        margin: auto;
    }
}

.additional-data-divider {
    position: relative;
    width: 100%;

    &:after {
        content: "";
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        width: 75%;
        margin: auto;
        height: 1px;
        background: #ededed;
    }
}

.divider {
    //noinspection SassScssUnresolvedMixin
    @include tablet {
        .column {
            position: relative;

            &:not(:last-child) {
                &:after {
                    content: "";
                    top: 0;
                    right: 0;
                    width: 1px;
                    bottom: 0;
                    height: 75%;
                    margin: auto;
                    position: absolute;
                    background: #EDEDED;
                }
            }
        }
    }
    
    //noinspection SassScssUnresolvedMixin
    @include mobile {
        .column {
            position: relative;
            
            &:not(:last-child) {
                &:after {
                    content: "";
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: absolute;
                    width: 75%;
                    margin: auto;
                    height: 1px;
                    background: #ededed;
                }
            }
        }
    }
}

.card {
    ::v-deep {
        .card-header {
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);

            .card-header-title {
                text-align: center;
                padding: 10px;
                justify-content: center;
            }
        }

        .card-content {
            padding: 10px;
        }
    }
}

.leads,
.registrations {
    ::v-deep {
        .card-header {
            background: #ecf6ff;

            .card-header-title {
                color: #1a55af;
            }
        }
    }
}

.approve,
.conversion {
    ::v-deep {
        .card-header {
            background: #f6f4ff;

            .card-header-title {
                color: #5843be;
            }
        }
    }
}

.commission {
    ::v-deep {
        .card-header {
            background: #fffbf0;

            .card-header-title {
                color: #427a0a;
            }
        }
    }
}

.debit {
    ::v-deep {
        .card-header {
            background: #ffece6;

            .card-header-title {
                color: #fa5f1c;
            }
        }
    }
}

.prepaid {
    ::v-deep {
        .card-header {
            background: #fdff9e;

            .card-header-title {
                color: #cf9c38;
            }
        }
    }
}

.cashFlow {
    ::v-deep {
        .card-header {
            background: #e3f8ef;

            .card-header-title {
                color: #00855B;
            }
        }
    }
}
