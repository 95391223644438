
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

















































































































































$portrait-orientation: portrait;
$landscape-orientation: landscape;

::v-deep {
    .modal-card {
        &:not(.is-full) {
            width: 90vw;
            max-width: 90vw;
        }

        &.is-full {
            .modal-card {
                &-head, &-foot {
                    padding: 0;
                    height: 20px;
                    background-color: whitesmoke;
                }

                &-body, {
                    background-color: whitesmoke;
                }
            }
        }
    }

    .modal-close {
        background-color: rgba(10, 10, 10, 0.2);

        &:hover {
            background-color: rgba(10, 10, 10, 0.3);
        }
    }
}

.modal {
    @media only screen and (max-width: 825px) {
        ::v-deep {
            .chart {
                .card-content {
                    padding: 0;
                    width: 100%;
                }
            }
        }

        ::v-deep {
            .modal-card {
                &-body {
                    .chart-filters {
                        display: none;
                    }
                }
            }

            .modal-close {
                display: none;
            }
        }

        &.is-portrait-primary {
            ::v-deep {
                .chart {
                    height: 93vh;
                    min-height: 93vh;

                    .card-content {
                        height: 93vh;
                    }
                }
            }

            @media (orientation: $landscape-orientation) {
                transform: rotate(90deg) translate(0%, -100%);
                width: 100vh;
                height: 100vw;
                transform-origin: 0 0;

                ::v-deep {
                    .animation-content, .modal-card {
                        height: 100vw;
                        max-height: 100vw;
                    }

                    .chart {
                        height: 93vw;
                        min-height: 93vw;

                        .card-content {
                            height: 93vw;
                        }
                    }
                }
            }
        }

        &.is-portrait-secondary {
            @media (orientation: $portrait-orientation) {
                transform: rotate(180deg);

                ::v-deep {
                    .chart {
                        height: 93vh;
                        min-height: 93vh;

                        .card-content {
                            height: 93vh;
                        }
                    }
                }
            }

            @media (orientation: $landscape-orientation) {
                transform: rotate(270deg) translate(-100%, 0%);
                width: 100vh;
                height: 100vw;
                transform-origin: 0 0;

                ::v-deep {
                    .animation-content, .modal-card {
                        height: 100vw;
                        max-height: 100vw;
                    }

                    .chart {
                        height: 93vw;
                        min-height: 93vw;

                        .card-content {
                            height: 93vw;
                        }
                    }
                }
            }
        }

        &.is-landscape-primary {
            ::v-deep {
                .chart {
                    height: 90vh;
                    min-height: 90vh;

                    .card-content {
                        height: 90vh;
                    }
                }
            }

            @media (orientation: $portrait-orientation) {
                transform: rotate(90deg) translate(0%, -100%);
                width: 100vh;
                height: 100vw;
                transform-origin: 0 0;

                ::v-deep {
                    .chart {
                        height: 90vw;
                        min-height: 90vw;

                        .card-content {
                            height: 90vw;
                        }
                    }
                }
            }
        }

        &.is-landscape-secondary {
            @media (orientation: $portrait-orientation) {
                transform: rotate(270deg) translate(-100%, 0%);
                width: 100vh;
                height: 100vw;
                transform-origin: 0 0;

                ::v-deep {
                    .chart {
                        height: 90vw;
                        min-height: 90vw;

                        .card-content {
                            height: 90vw;
                        }
                    }
                }
            }

            @media (orientation: $landscape-orientation) {
                transform: rotate(180deg);
                width: 100vw;
                height: 100vh;
                transform-origin: center;

                ::v-deep {
                    .chart {
                        height: 90vh;
                        min-height: 90vh;

                        .card-content {
                            height: 90vh;
                        }
                    }
                }
            }
        }
    }
}
