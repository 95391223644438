
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
































































































::v-deep {
  .no-padding {
    padding-bottom: 0 !important;

    .tab-content {
      padding: 0 !important;
    }
  }
}

.card-user {
  @media screen and (min-width: 770px) {
    min-width: 600px;
  }
}
