
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



























































































div {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 0.5rem 0;

            &:not(:last-child) {
                border-bottom: 1px solid #eaeaea;
            }

            &::v-deep {
                figure {
                    overflow: hidden;
                    margin-right: 1em;
                    border-radius: 6px;
                    border: 1px solid #ececec;
                }
            }

            .price {
                font-size: smaller;
            }

            .level-left {
                flex-shrink: 1;

                span {
                    word-break: break-word;
                }
            }
        }
    }
}
