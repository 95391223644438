@font-face {
  font-family: 'CompetitionFont';
  src: url('~@/assets/fonts/Geologica-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'CompetitionFont';
  src: url('~@/assets/fonts/Geologica-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

%styleText {
  font-weight: 600;
  color: #FFFFFF;
}

%verticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%beforeStyle {
  content: "";
  position: absolute;
  z-index: 1;

  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

$color-khaki: #3A5808;
$main-color: #EF5698;
$second-color: #0BB8A9;
$light-gray_: #767676;
$dark-brown: #363636;

.rating-banner {
  $vertical-padding: 40;
  $self: &;

  position: relative;
  display: flex;
  width: 100%;

  padding: clamp(8px, 2vw, 40px);
  margin-bottom: 16px;

  font-family: "CompetitionFont";
  font-weight: 500;
  color: white;

  overflow: hidden;
  border-radius: 18px;

  height: 18.75vw;
  min-height: 235px;
  max-height: 360px;

  background: url("~@/assets/Competition/Raffle/Banner-Big.png") bottom;
  background-size: cover;

  @media screen and (max-width: 1407px) {
    background-size: cover;
  }

  @media screen and (max-width: 820px) {
    height: 35vw;
    min-height: 170px;

    background: url("~@/assets/Competition/Raffle/Banner-Mobile.png");
    background-size: cover;
  }
}