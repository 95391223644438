::v-deep {
  .table {
    thead {
      tr {
        th {
          background: #F7F7F7 !important;
        }

        th:first-child {
          border-top-left-radius: 10px;
        }

        th:last-child {
          border-top-right-radius: 10px;
        }
      }
    }

    tr {
      $color-border: #EDEDED;

      &:first-of-type td:nth-last-child(1),
      &:first-of-type td:first-child {
        border-radius: 0;
      }

      &:last-of-type td {
        border-bottom: 1px solid $color-border;
      }

      td {

        &:first-child {
          border-left: 1px solid $color-border;
        }
        &:last-child {
          border-right: 1px solid $color-border;
        }
      }
    }
  }
}