
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






























































































































































































































































































































































@import "~@/scss/dashboard";

.card {
  width: inherit;
  height: inherit;

  font-size: 1rem;
}

::v-deep {
  g line {
    display: none;
  }

  .table-wrapper {
    max-height: 628px;
  }
}
