
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        








































@import "~@/scss/competition-banner";

.rating-banner {
  $self: &;

  &_wrapper-prizes {
    position: absolute;
    top:  clamp(22px, 2vw, 40px);
    right: 0;

    display: flex;
    width: clamp(200px, 31.7vw, 640px);

    @media screen and (max-width: 1407px) {
      width: clamp(260px, 27.7vw, 400px);
    }

    #{$self}_prize {
      //@extend %verticalCenter;

      position: absolute;
      top: clamp(0.375rem, -2.3173rem + 3.0769vw, 1.375rem);
      z-index: 3;

      border-radius: clamp(8px, 1.2vw, 24px);
      padding: clamp(4px, 0.45vw, 8px);

      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.35);
      background: black;
      transform: rotate(9deg);

      @media screen and (max-width: 1407px) {
        top: -10px;
      }

      #{$self}_prize-img {
        width: clamp(4.625rem, 10.921vw + 1.895rem, 15rem);
        height: clamp(4.625rem, 10.921vw + 1.895rem, 15rem);

        background-image: url("~@/assets/Competition/Raffle/avatar-5k.png");
        background-size: cover;

        border-radius: clamp(5px, 0.8vw, 16px);
        margin-bottom: 10px;

        @media screen and (max-width: 1407px) {
          width: clamp(120px, 9.2vw, 128px);
          height: clamp(120px, 9.2vw, 128px);
        }
      }

      &:nth-child(2) {
        transform: rotate(9deg);
        top: 10px;
        left: clamp(1.5625rem, -1.2336rem + 11.1842vw, 12.1875rem);
        z-index: 2;
        filter: blur(1px);

        @media screen and (max-width: 1407px) {
          left: clamp(95px, 9.6vw, 135px);
          top: -12px;
        }

        #{$self}_prize-img {
          background-image: url("~@/assets/Competition/Raffle/avatar-3k.png");
          height: clamp(3.375rem, 0.7599rem + 10.4605vw, 13.3125rem);

          @media screen and (max-width: 1407px) {
            width: clamp(105px, 7.75vw, 109px);
            height: clamp(105px, 7.75vw, 109px);
          }
        }
      }
      &:nth-child(3) {
        transform: rotate(9deg);
        left: clamp(8.125rem, 3.9309rem + 16.7763vw, 24.0625rem);
        filter: blur(2px);
        top: -10px;
        z-index: 1;

        @media screen and (max-width: 1407px) {
          left: clamp(175px, 17.8vw, 250px);
          top: -18px;
        }

        #{$self}_prize-img {
          background-image: url("~@/assets/Competition/Raffle/avatar-2k.png");
          height: clamp(3.125rem, 0.8882rem + 8.9474vw, 11.625rem);

          @media screen and (max-width: 1407px) {
            width: clamp(90px, 6.5vw, 91px);
            height: clamp(90px, 6.5vw, 91px);
          }
        }
      }

      &-place {
        position: absolute;
        top: -10px;
        left: -10px;

        width: clamp(48px, 3.25vw, 64px);
        height: clamp(48px, 3.25vw, 64px);

        padding: clamp(5px, 3.25vw, 7px);
        box-shadow: 8px 8px 0 0 black;
        background: $second-color;
        border: clamp(2px, 0.2vw, 4px) solid white;
        transform: rotate(46deg);

        @media screen and (max-width: 1407px) {
          width: 40px;
          height: 40px;
          padding: 4px;
        }

        &>div {
          height: 100%;
        }
      }

      &-info {
        font-weight: 800;
        font-size: clamp(16px, 2.1vw, 40px);
        line-height: 0.9;
        letter-spacing: -0.04em;
        text-align: center;

        margin-bottom: clamp(0.5rem, 0.3026rem + 0.7895vw, 1.25rem);
      }
    }

    @media screen and (max-width: 820px) {
      display: none;
    }
  }
}
